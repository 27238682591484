import { MainLayout } from '@/layouts';
import { DashboardView, DashboardViewSkeleton, useDashboardPageData } from '@/views';
import { useDialog } from '@/components/Dialog/DialogHandler';

export type Role = 'admin' | 'default';
const user: {
    role: Role;
} = {
    role: 'default',
};

export default function Home() {
    const dialog = useDialog();
    const { data: DashboardData, isLoading, isError } = useDashboardPageData();

    if (isError) {
        dialog({
            type: 'toast',
            variant: 'error',
            content: 'Algo ha fallado, por favor inténtalo de nuevo',
        });
    }

    if (isLoading || isError || !DashboardData) {
        return <DashboardViewSkeleton />;
    }

    return (
        <MainLayout className="max-w-screen p-6">
            <DashboardView role={user.role} data={DashboardData} />
        </MainLayout>
    );
}
